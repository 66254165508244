import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const OurServices = () => {
    return (
        <React.Fragment>


            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100 pt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">

                                    
                                    <h2>BgreenのJamstack<br />モダンWeb開発</h2>
                                    <p>株式会社Bgreenでは「よりよい性能」「より高いセキュリティ」を誇るJamstack開発を推奨しております。<br />実装により高速表示が実現できるため、「SEO対策」にも効果があります。<br />ぜひご相談ください。</p>
                                    <div className="logo-area">
                                        <ul>
                                            <li><StaticImage src="../../assets/images/logo_gatsby.jpg" alt="gatsby" placeholder="blurred" /></li>
                                            <li><StaticImage src="../../assets/images/logo_github.jpg" alt="github" placeholder="blurred" /></li>
                                            <li><StaticImage src="../../assets/images/logo_netlify.jpg" alt="netlify" placeholder="blurred" /></li>
                                        </ul>
                                    </div>
                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Gastby
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Netlify
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                           GitHub
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            microCMS
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            HyperForm
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            React
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <StaticImage src="../../assets/images/service2.png" alt="service" placeholder="blurred" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;