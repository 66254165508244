import React from 'react'
import ReactWOW from 'react-wow'
import { StaticImage } from "gatsby-plugin-image";
const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <span className="title">CREATIVE<i class='bx bx-chevrons-right'></i><br />MARKETING</span>
                                <h1>貴社のオンラインビジネスを<br />徹底的にサポートいたします。</h1>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <StaticImage src="../../assets/images/banner-img1.png" alt="banner"  placeholder="blurred" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner