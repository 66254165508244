import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const TeamMember = () => {
    return (
        <section className="scientist-area pt-100 pb-70" id="members">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="team" placeholder="blurred" /> 
                        Team Members
                    </span>
                    <h2>メンバー紹介</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <StaticImage src="../../assets/images/member1.jpg" alt="team" placeholder="blurred" />


                            </div>
                            <div className="content">
                                <h3>Toshikaz KANEDA</h3>
                                <span>CEO & Director</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <StaticImage src="../../assets/images/member2.jpg" alt="team" placeholder="blurred" />


                            </div>
                            <div className="content">
                                <h3>T. NAGATA</h3>
                                <span>Designer</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <StaticImage src="../../assets/images/member3.jpg" alt="team" placeholder="blurred" />


                            </div>
                            <div className="content">
                                <h3>T. NOHARA</h3>
                                <span>Designer</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TeamMember;