import React from 'react'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image";


const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70" id="features">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="star" placeholder="blurred" /> 
                        Our Features
                    </span>
                    <h2>Bgreenのウェブサービス</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <StaticImage src="../../assets/images/service_logo01.jpg" alt="service" placeholder="blurred" />
                            </div>
                            <h3>
                                <Link to="https://bgreen.jp/lp/">
                                Bgreen LP
                                </Link>
                            </h3>
                            <p>格安LP制作サービス</p>

                            <Link to="https://bgreen.jp/lp/" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <StaticImage src="../../assets/images/service_logo02.jpg" alt="service" placeholder="blurred" />
                            </div>

                            <h3>
                                <Link to="https://sakuweb.site/">
                                    サクウェブ
                                </Link>
                            </h3>

                            <p> 格安HP制作サービス</p>
                            
                            <Link to="https://sakuweb.site/" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <StaticImage src="../../assets/images/service_logo03.jpg" alt="service" placeholder="blurred" />
                            </div>

                            <h3>
                                <Link to="https://osakelist.com/">
                                Osakelist
                                </Link>
                            </h3> 

                            <p> 日本酒のウェブメディア</p>
                            
                            <Link to="https://osakelist.com/" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;