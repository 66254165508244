import React from 'react'

import { StaticImage } from "gatsby-plugin-image";

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f2faf5" id="services">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="feature" placeholder="blurred" /> 
                        Our Services
                    </span>

                    <h2>Bgreenができること</h2>
                </div>

                <div className="row">

                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/s_logo02.jpg" alt="feature" placeholder="blurred" />
                            </div>
                            <h3>ウェブ制作</h3>
                            <p>コーポレートサイトやEC構築。お客様のご要望に合わせて最適なプランをご提供いたします。</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/s_logo01.jpg" alt="feature" placeholder="blurred" />
                            </div>
                            <h3>ウェブマーケティング</h3>
                            <p>SNS広告、リスティング広告運用の代行を行なっております。また楽天の運用代行も承っております。</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/s_logo03.jpg" alt="feature" placeholder="blurred" />
                            </div>
                            <h3>ウェブサイト保守業務</h3>
                            <p>新着情報の更新やバナー作成、キャンペンページの作成。月額プランを低価格にてご用意しております。</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures