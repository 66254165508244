import React from 'react'
import { Link as Scroll } from 'react-scroll';
import { StaticImage } from "gatsby-plugin-image";

const ProjectStartArea = () => {
    return (
        <div className="project-start-area ptb-100" id="recruit">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <StaticImage src="../../assets/images/project-start1.png" alt="project" placeholder="blurred" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>We are hiring!</h2>
                            <p>一緒に挑戦してくれるメンバーを募集しています。<br />
                            ・募集職種：webデザイナー<br />
                            ・雇用形態：雇用形態	正社員・アルバイト<br />
                            ・勤務地：フルリモート
                            </p>
                            <Scroll 
                                    to="contact" 
                                    smooth={true}
                                    className="default-btn"
                            >                
                            <i className="flaticon-right"></i> Contact <span></span>
                            </Scroll>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <StaticImage src="../../assets/images/circle-shape1.png" alt="project" placeholder="blurred" />
            </div>
        </div>
    )
}

export default ProjectStartArea;