import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import TeamMember from "../components/Index/TeamMember"

import ProjectStartArea from "../components/Index/ProjectStartArea"

import ContactForm from "../components/Contact/ContactForm"

import {graphql, Link } from 'gatsby'
import {  StaticImage } from "gatsby-plugin-image"

import starIcon from '../assets/images/star-icon.png'
const Home = ({ data }) => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <OurSolutions />
      <OurFeatures />
      <OurServices />

      <section className="blog-area pt-100 pb-70 bg-fffbf5">
          <div className="container">
              <div className="section-title">
                  <span className="sub-title">
                      <img src={starIcon} alt="blog" /> 
                      Our Blog
                  </span>
                  <h2>新着情報</h2>
              </div>
              <div className="row">

               {data.allMicrocmsBlog.edges.map(({ node }) => (
        <div className="col-lg-4 col-md-6" key={node.slug}>
                      <div className="single-blog-post bg-fffbf5">
                          <div className="post-image">
                          <Link to={`/blog/${node.blogId}`}>

                              {node.eyecatch ? (
          <img
            src={node.eyecatch.url}
            as={`figure`}
            alt={node.title}
            style={{ aspectRatio: "25/14" }}
          />
        ) : (
          <StaticImage
            src={`../assets/images/explore-learning.jpg`}
            alt=""
            as={`figure`}
            style={{ aspectRatio: "25/14" }}
          />
        )}
                              </Link>
                          </div>

                          <div className="post-content">
                              <ul className="post-meta d-flex justify-content-between align-items-center">
                                  <li>
                                      <div className="post-author d-flex align-items-center">
                                      
                                      Bgreen Staff
                                      </div>
                                  </li>
                                  <li>
                                      <i className='flaticon-calendar'></i>{node.publishedAt}
                                  </li>
                              </ul>
                              <h3>
                                  <Link to={node.uri}>
                                      {node.title}
                                  </Link>
                              </h3>
                          </div>
                      </div>
                  </div>
        ))}
              </div>
          </div>
      </section>
      <TeamMember />
      <ProjectStartArea />
      <ContactForm />
      <Footer />
    </Layout>
  )
}

export default Home


export const query = graphql`
query {
allMicrocmsBlog(limit:3,sort: { fields: [createdAt], order: DESC }){
  edges {
    node {
      blogId
      title
      publishedAt(formatString: "YYYY/MM/DD")
      eyecatch {
        url
      }             
    }
  }
}
} 
`