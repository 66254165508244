import React from 'react'
import { StaticImage } from "gatsby-plugin-image";
const ContactForm = () => {
    return (
        <section className="contact-area pb-100" id="contact">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="contact" placeholder="blurred" /> 
                        Get in Touch
                    </span>
                    <h2>お問い合わせ</h2>
                    <p>サービスに関するご相談、お見積りなどお気軽にお問い合わせください。</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <StaticImage src="../../assets/images/contact.png" alt="contact" placeholder="blurred" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" name="membership-form" method="POST" data-netlify="true" action="/thanks">
                            <input type="hidden" name="form-name" value="membership-form" />

                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="お名前" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Eメールアドレス" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="お問い合わせ内容をご記入ください" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm